<template>
  <div class="labour-authentication">
    <div class="scroll-box">
      <van-form
        ref="rcrz_form"
        class="rcrz-form"
        input-align="right"
        error-message-align="right"
        validate-trigger="onSubmit"
        :readonly="mode === 'view'"
        :colon="true"
        :scroll-to-error="true"
        :show-error="false"
        :show-error-message="true"
        :submit-on-enter="false"
        label-width="6.4em"
        @submit="submitOnHandle"
      >
        <div class="form-moudlar no-margin-top">
          <div class="moudlar-title">
            <div class="title-text">个人信息</div>
          </div>
          <van-field
            required
            v-model="form.realName"
            label="姓名"
            placeholder="请输入姓名"
            :maxlength="10"
            :rules="[{ required: true, message: '姓名不能为空' }]"
          />
          <van-field
            required
            v-model="form.mobile"
            label="联系方式"
            placeholder="请输入联系方式"
            :maxlength="11"
            :rules="[
              { required: true, message: '联系方式不能为空' },
              {
                pattern: /^(?:(?:\+|00)86)?1[3-9]\d{9}$/,
                message: '手机号码不正确',
              },
            ]"
          />
        </div>
        <div class="form-moudlar">
          <div class="moudlar-title">
            <div class="title-text">上传证明</div>
          </div>
          <van-field
            class="work-photo"
            required
            label="“甬工会”电子会员卡截图"
            input-align="left"
            error-message-align="left"
            label-width="100%"
            :rules="[{ required: true, message: '截图不能为空' }]"
          >
            <template #input>
              <v-upload
                ref=""
                class=""
                :imgUrl.sync="form.unionMemberPicShow"
                :activityPicture.sync="form.unionMemberPic"
                :maxCount="1"
                :readonly="mode !== 'edit'"
                :show-upload="mode === 'edit'"
                :deletable="mode === 'edit'"
              />
              <div class="example-pic">
                <div class="tip-text">示例<br />图片</div>
                <img
                  class="example-img"
                  src="./img/screenshot01.png"
                  @click="sltpOnClick(0)"
                />
              </div>
            </template>
          </van-field>
          <van-field
            class="work-photo"
            required
            label="电子会员卡截图二维码"
            input-align="left"
            error-message-align="left"
            label-width="100%"
            :rules="[{ required: true, message: '截图不能为空' }]"
          >
            <template #input>
              <v-upload
                ref=""
                class=""
                :imgUrl.sync="form.unionMemberQrcodeShow"
                :activityPicture.sync="form.unionMemberQrcode"
                :maxCount="1"
                :readonly="mode !== 'edit'"
                :show-upload="mode === 'edit'"
                :deletable="mode === 'edit'"
              />
              <div class="example-pic">
                <div class="tip-text">示例<br />图片</div>
                <img
                  class="example-img"
                  src="./img/screenshot02.png"
                  @click="sltpOnClick(1)"
                />
              </div>
            </template>
          </van-field>
        </div>
      </van-form>
      <div class="form-tip-text">
        说明：目前本功能仅针对工会关系在明珠社区的会员。
      </div>
    </div>
    <div class="footer-btn">
      <div
        v-if="![1, 0].includes(rzAuditStatus)"
        class="btn tj-btn"
        @click="submitOnClick"
      >
        <van-loading
          v-show="submitLoading"
          type="spinner"
          color="#ffffff"
          size="18"
          style="margin-right: 10px"
        />
        提交
      </div>
      <div v-if="[1].includes(rzAuditStatus)" class="btn shtg-btn">
        审核通过
      </div>
      <div
        v-if="[0].includes(rzAuditStatus)"
        class="btn dsh-btn"
        @click="revoOnClick"
      >
        待审核，点击撤回
      </div>
    </div>
  </div>
</template>

<script>
import {
  userInfoUrl,
  getTalentsIntroduceInfoByUserId,
  saveOrUpdateTalentsIntroduce,
  getRyGhhyAuthInfoUrl,
  getUserRealNameAuthInfoUrl,
  getRyGhhyAuthDetailUrl,
  saveRyGhhyAuthInfoUrl,
  revoRyGhhyAuthInfoUrl,
} from "./api.js";
import { mapState } from "vuex";
// import moment from 'moment';
import { Button, Form, ImagePreview, Loading } from "vant";
// import wx from 'weixin-js-sdk';
import _ from "lodash";
import screenshot01 from "./img/screenshot01.png";
import screenshot02 from "./img/screenshot02.png";

export default {
  name: "labourAuthentication",
  components: {
    [Button.name]: Button,
    [Form.name]: Form,
    [Loading.name]: Loading,
  },
  props: {},
  model: {},
  data() {
    return {
      mode: "edit",
      rzAuditStatus: "", // 认证审核状态（整型）: 0:待审核    1:审核通过    2:审核驳回
      form: {
        type: 2,
        userId: "",
        tenantId: "",
        id: "",
        realName: "", // 姓名
        mobile: "", // 联系方式
        unionMemberPic: "",
        unionMemberPicShow: "",
        unionMemberQrcode: "",
        unionMemberQrcodeShow: "",
      },
      submitLoading: false,
      unPassReason: "",
      falseValues: ["", null, void 0, NaN],
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  watch: {},
  created() {
    this.getRyGhhySmxx();
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    sltpOnClick(startPosition) {
      ImagePreview({ images: [screenshot01, screenshot02], startPosition });
    },
    getRyGhhySmxx() {
      this.$axios
        .get(getRyGhhyAuthInfoUrl, {
          params: { type: "2", userId: this.userId },
        })
        .then((res) => {
          if (res && res.code === 200) {
            res.data = res.data || {};
            Object.keys(this.form).forEach((k) => {
              if (["unionMemberPicShow", "unionMemberQrcodeShow"].includes(k)) {
                k === "unionMemberPicShow" &&
                  (this.form[k] = res.data.unionMemberPic);
                k === "unionMemberQrcodeShow" &&
                  (this.form[k] = res.data.unionMemberQrcode);
              } else {
                this.form[k] = res.data[k] || "";
              }
            });
            this.rzAuditStatus = res.data.auditStatus;
            this.unPassReason = res.data.unPassReason;
            if (this.rzAuditStatus === 2) {
              this.$dialog
                .alert({
                  title: "审核驳回",
                  message: "驳回原因：" + this.unPassReason,
                  confirmButtonText: "重新提交",
                  confirmButtonColor: "#1989fa",
                })
                .then(() => {
                  [
                    "realName",
                    "mobile",
                    "unionMemberPic",
                    "unionMemberPicShow",
                    "unionMemberQrcode",
                    "unionMemberQrcodeShow",
                  ].forEach((k) => {
                    this.form[k] = "";
                  });
                });
            }
          }
        });
    },
    submitOnHandle() {
      if (this.submitLoading === true) return;
      this.submitLoading = true;
      const form = _.cloneDeep(this.form);
      form.tenantId = this.tenantId;
      form.userId = this.userId;
      form.type = 2;
      Reflect.deleteProperty(form, "unionMemberPicShow");
      Reflect.deleteProperty(form, "unionMemberQrcodeShow");
      if (this.falseValues.includes(form.id)) {
        Reflect.deleteProperty(form, "id");
      }
      this.$axios
        .post(saveOrUpdateTalentsIntroduce, form)
        .then((res) => {
          if (res && res.code == 200) {
            this.$toast(res.msg);
            this.$router.go(-1);
          }
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
    submitOnClick() {
      this.$refs.rcrz_form.submit();
    },
    revoOnClick() {
      this.$dialog
        .confirm({
          title: "操作提示",
          message: "是否撤回申请？",
          confirmButtonText: "确定",
          confirmButtonColor: "#1989fa",
        })
        .then(() => {
          this.$axios
            .post(
              revoRyGhhyAuthInfoUrl,
              this.$qs.stringify({ id: this.form.id })
            )
            .then((res) => {
              if (res && res.code === 200) {
                this.$toast(res.msg);
                this.getRyGhhySmxx();
              }
            });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
// constant(safe-area-inset-*)
// env(safe-area-inset-*)
// safe-area-inset-top
// safe-area-inset-right
// safe-area-inset-bottom
// safe-area-inset-left

.labour-authentication {
  box-sizing: border-box;
  height: 100%;
  background-color: #fafafa;
  padding-bottom: calc(constant(safe-area-inset-bottom));
  padding-bottom: calc(env(safe-area-inset-bottom));
  overflow: hidden;
}
.form-moudlar {
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 16px;
  overflow: hidden;
  margin-top: 20px;
  box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
  &.no-margin-top {
    margin-top: 0px;
  }
}
.moudlar-title {
  box-sizing: border-box;
  padding-top: 20px;
  padding-bottom: 10px;
  margin: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title-text {
    box-sizing: border-box;
    font-size: 26px;
    line-height: 32px;
    height: 36px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
    position: relative;
    .sub-text {
      font-size: 20px;
      color: rgba(0, 0, 0, 0.5);
    }
  }
}
.work-photo {
  flex-wrap: wrap;
  /deep/ .van-field__value {
    box-sizing: border-box;
    width: 100%;
    flex-shrink: 0;
    flex-basis: auto;
  }
}
.pzsc-content {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px;
  /deep/ .v-upload {
    width: calc(50% - 10px);
    height: 200px;
    .van-uploader {
      width: 100%;
      height: 100%;
      .van-uploader__wrapper {
        width: 100%;
        height: 100%;
        .van-uploader__upload {
          width: 100%;
          height: 100%;
          .van-uploader__upload-icon {
            display: none;
          }
          .van-uploader__input {
            opacity: 0;
          }
          background-repeat: no-repeat;
          background-position: 50% 50%;
          background-size: 100% 100%;
        }
        .van-uploader__preview,
        .van-uploader__preview-image {
          width: 100%;
          height: 100%;
          margin: 0px;
        }
      }
    }
    &.sfzzpsc-rx {
      .van-uploader__upload {
        background-image: url("./img/card_header.png");
      }
    }
    &.sfzzpsc-gh {
      .van-uploader__upload {
        background-image: url("./img/card_emblem.png");
      }
    }
  }
  .tip-text {
    box-sizing: border-box;
    flex-shrink: 0;
    width: 100%;
    font-size: 24px;
    color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    margin-top: 10px;
    img {
      height: 24px;
      margin-bottom: -3px;
      margin-right: 8px;
    }
    span {
      color: #ff8c35;
    }
  }
  .error-text {
    box-sizing: border-box;
    flex-shrink: 0;
    width: 100%;
    font-size: 24px;
    color: #ee0a24;
    margin-top: 6px;
  }
}
.scroll-box {
  box-sizing: border-box;
  height: calc(100% - 130px);
  padding: 32px;
  overflow-x: hidden;
  overflow-y: auto;
}
.footer-btn {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px 32px;
  position: fixed;
  left: 0px;
  bottom: 0px;
  bottom: constant(safe-area-inset-bottom);
  bottom: env(safe-area-inset-bottom);
  .btn {
    box-sizing: border-box;
    width: 80%;
    height: 66px;
    border-radius: 10px;
    font-size: 32px;
    line-height: 40px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    &.tj-btn {
      background: linear-gradient(180deg, #72a8ff 0%, #3680ff 100%);
    }
    &.dsh-btn {
      background: linear-gradient(180deg, #ffc055 0%, #fe9514 100%);
    }
    &.shtg-btn {
      background: linear-gradient(180deg, #5ee6c4 0%, #30c991 100%);
    }
  }
}
.smshzt-tag {
  box-sizing: border-box;
  flex-shrink: 0;
  height: 32px;
  padding: 0px 8px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  border: 1px solid;
  &.success {
    color: #42db71;
    border-color: #42db71;
    background-color: rgba(66, 219, 113, 0.1);
  }
  &.inreview {
    color: #ff976a;
    border-color: #ff976a;
    background-color: rgba(255, 151, 106, 0.1);
  }
  &.reject {
    color: #ee0a24;
    border-color: #ee0a24;
    background-color: rgba(238, 10, 36, 0.1);
  }
}
.example-pic {
  box-sizing: border-box;
  width: 200px;
  height: 200px;
  border-radius: 4px;
  position: relative;
  margin-left: 15px;
  .tip-text {
    position: absolute;
    font-size: 20px;
    left: 0px;
    top: 0px;
    border-radius: 4px 0px 4px 0px;
    // width: 50px;
    line-height: 24px;
    background-color: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;
  }
  .example-img {
    box-sizing: border-box;
    width: 200px;
    height: 200px;
    object-fit: contain;
    padding: 10px;
    border-radius: 4px;
    border: 1px dashed rgba(0, 0, 0, 0.25);
  }
}
.form-tip-text {
  font-size: 24px;
  color: #ee0a24;
  margin-top: 20px;
}
.work-describe {
  flex-wrap: wrap;
  /deep/ .van-field__value {
    box-sizing: border-box;
    width: 100%;
    background-color: #f9f9f9;
    flex-shrink: 0;
    flex-basis: auto;
    padding: 8px 20px 8px 20px;
    border-radius: 16px;
  }
}
</style>
